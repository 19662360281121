import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Typography,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: 300,
    margin: "auto",
  },
  media: {
    height: 300,
    width: "100%",
  },
  name: {
    textDecoration: "none",
  },
});

const CardUi = ({ image, name, url, api, status }) => {
  const classes = useStyles();

  return (
    <>
      <a className={classes.name} rel="noopener noreferrer" href={url}>
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia className={classes.media} image={image} />
            <CardContent>
              <Typography
                align="center"
                gutterBottom
                variant="h6"
                component="h3"
              >
                {name}
              </Typography>
            </CardContent>
          </CardActionArea>
          <Typography align="center" component="div">
            {status}
          </Typography>
        </Card>
      </a>
      {!api ? null : (
        <div style={{ width: "300px", margin: "auto" }}>
          <Button
            href={url}
            variant="contained"
            color="primary"
            style={{ width: "47%", margin: "1%" }}
          >
            Website
          </Button>
          <Button
            href={api}
            target="_blank"
            variant="contained"
            color="primary"
            style={{ width: "47%", margin: "1%" }}
          >
            API
          </Button>
        </div>
      )}
    </>
  );
};

export default CardUi;
