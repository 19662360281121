import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { lightBlue } from "@material-ui/core/colors";
import config from "../config.json";

export default function Footer() {
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "fixed",
      left: 0,
      bottom: 0,
      width: "100%",
      fontSize: "0.8em",
      color: "white",
      textAlign: "center",
      backgroundColor: lightBlue[500],
    },
  }));
  const classes = useStyles();

  return <div className={classes.root}>{config.buildNum}</div>;
}
