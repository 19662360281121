import React from "react";
import CardUi from "./components/Card";
import {
  Grid,
  CssBaseline,
  Modal,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
} from "@material-ui/core";
import Sam from "./assets/images/SAM.png";
import Approval from "./assets/images/AppApproval.png";
import MemHelper from "./assets/images/MemWebhelper.png";
import Comcast from "./assets/images/location.jpg";
import MenuBar from "./components/MenuBar";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./components/Footer";
import Theme from "./shared/Theme";
import cloud from "./assets/images/cloud.png";

const useStyles = makeStyles({
  root: {
    marginTop: 50,
    marginRight: 10,
    marginLeft: 10,
  },
  gridItem: {
    marginBottom: 15,
  },
  modal: {
    display: "flex",
    padding: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  media: {
    height: 300,
    width: "100%",
  },
  paper: {
    width: 400,
    // backgroundColor: "#fff",
    // border: "2px solid #000",
    boxShadow: 5,
    padding: 5,
  },
});

function App() {
  const [darkMode, setDarkMode] = React.useState(
    localStorage.getItem("darkMode") || "false"
  );
  React.useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  //Convert to and from a boolean for ease of use elsewhere
  const darkModeBool = JSON.parse(darkMode);
  const updateDarkMode = (e) => {
    setDarkMode(e.target.checked.toString());
  };

  const classes = useStyles();
  const rootRef = React.useRef(null);

  return (
    <Theme darkState={darkModeBool}>
      <div>
        <CssBaseline />
        <MenuBar checked={darkModeBool} switchTheme={updateDarkMode} />
        <div className={classes.root}>
          <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open
            aria-labelledby="server-modal-title"
            aria-describedby="server-modal-description"
            className={classes.modal}
            container={() => rootRef.current}
          >
            <div className={classes.paper}>
              <Card>
                <CardMedia className={classes.media} image={cloud} />
                <CardContent>
                  <Typography
                    align="center"
                    gutterBottom
                    variant="h6"
                    component="h3"
                  >
                    As part of the SteelCloud shutdown, the tools hosted on
                    mem.comcast.net have moved to uem.comcast.net please update
                    your bookmarks to the new locations.
                  </Typography>
                  <Typography align="center" component="div">
                    <Button
                      href="https://uem.comcast.net/"
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "2%" }}
                    >
                      Go to the new Site
                    </Button>
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Modal>
          <Grid container>
            <Grid
              className={classes.gridItem}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <CardUi
                image={Sam}
                url="https://sam.uem.comcast.net/"
                name="Software Asset Management"
              />
            </Grid>
            <Grid
              className={classes.gridItem}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <CardUi
                image={MemHelper}
                name="MEM Web Helper"
                url={"https://ui.uem.comcast.net/"}
                api={"https://ui.api.uem.comcast.net/"}
              />
            </Grid>
            <Grid
              className={classes.gridItem}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <CardUi
                image={Approval}
                name="App Approval"
                url={"https://appapproval.uem.comcast.net/"}
              />
            </Grid>
            <Grid
              className={classes.gridItem}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <CardUi
                image={Comcast}
                name="Comcast Locations"
                url={"https://locations.uem.comcast.net/"}
              />
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    </Theme>
  );
}

export default App;
